import dayjs from 'dayjs';

import styles from './_page.module.scss';
import {Button, DatePicker, Form, message, Select} from 'antd';
import {useEffect, useState, Fragment} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import CONFIG from '../../config';
import {TableRecords} from '../../components/TableRecords';
import {copyToClipboard} from '../../helper';

const endpoint = _pageConfig.endpoint;

const dateFormat = CONFIG.localDateFormat;
const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Ho_Chi_Minh');

export default function RecordsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [isShowAbsent, setIsShowAbsent] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [actions, data] = useStrapiRest(endpoint);
  const [userActions, userData] = useStrapiRest('users');
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('DEBUG data', data);
  }, [data]);

  useEffect(() => {
    (async () => {
      const todayStart = moment().format(CONFIG.isoFormat).valueOf();
      const todayEnd = moment().format(CONFIG.isoFormat).valueOf();

      await actions.getItems({
        sort: 'createdAt:desc',
        populate: ['user'],
        filters: {
          date: {
            $gte: todayStart,
            $lte: todayEnd,
          },
        },
        pagination: {
          page: currentPage,
          pageSize: pageSize,
        },
      });
      await userActions.getItems({
        sort: 'email:asc',
        filters: {
          blocked: false,
        },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      });
    })();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    let fromDate = urlParams.get('fromDate');
    let toDate = urlParams.get('toDate');

    if (userId && fromDate && toDate && userData?.list) {
      fromDate = dayjs(moment(fromDate).format(dateFormat).valueOf(), dateFormat);
      toDate = dayjs(moment(toDate).format(dateFormat).valueOf(), dateFormat);

      form.setFieldValue('user', Number(userId));
      form.setFieldValue('date_for_search', [fromDate, toDate]);
      form.submit();
    }
  }, [JSON.stringify(userData?.list)])

  async function onFinishSearch(values) {
    const clone = {
      ...values,
    };

    if (values.user) {
      setIsShowAbsent(!!values.user);
    }

    setDateFrom(clone.date_for_search[0].format(CONFIG.apiDateFormat).valueOf());
    setDateTo(clone.date_for_search[1].format(CONFIG.apiDateFormat).valueOf());

    const params = {
      sort: 'createdAt:desc',
      populate: ['user'],
      filters: {
        date: {
          $gte: clone.date_for_search[0].format(CONFIG.apiDateFormat).valueOf(),
          $lte: clone.date_for_search[1].format(CONFIG.apiDateFormat).valueOf(),
        },
      },
      pagination: {
        page: currentPage,
        pageSize: pageSize,
      },
    };

    if (values.user) {
      params.filters.user = {
        id: {
          $eq: values.user,
        },
      };
    }

    await actions.getItems(params);
  }

  function changeFilterDate(type) {
    let today = moment();

    if (type === 'yesterday') {
      today = today.subtract(1, 'days');
    }

    if (type === 'week') {
      type = 'isoWeek';
    }

    let from;
    let to;
    from = dayjs(today.startOf(type).format(dateFormat).valueOf(),
        dateFormat);

    to = dayjs(today.endOf(type).format(dateFormat).valueOf(),
        dateFormat);



    form.setFieldValue('date_for_search', [from, to]);
  }

  async function handleCopyLink() {
    const values = form.getFieldsValue();

    console.log('DEBUG values', values);

    if (values.user && values.date_for_search) {
      const target = `https://my.moha.team/records?userId=${values.user}&fromDate=${encodeURIComponent(values.date_for_search[0].toString())}&toDate=${encodeURIComponent(values.date_for_search[1].toString())}`;
      await copyToClipboard(target);
    } else {
      const target = `https://my.moha.team/records`;
      await copyToClipboard(target);
    }

    message.success('Copied to clipboard!')
  }

  function handleExportCsv() {
    const values = form.getFieldsValue();

    const formattedItems = userData.list.map(item => {
      return {
        email: item.email,
        link: `https://my.moha.team/records?userId=${item.id}&fromDate=${encodeURIComponent(values.date_for_search[0].toString())}&toDate=${encodeURIComponent(values.date_for_search[1].toString())}`
      }
    });

    // Write code to allow download csv file
    const csv = formattedItems.map(row => Object.values(row).join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'export.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    alert('Exported CSV file!');
  }

  return (
      <AuthLayout pageTitle={'Check in histories'}>
        <div className={'pageWrapper'}>
          <div className={'pageTitle'}></div>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">
                    <div className="text">Search results</div>
                    <Button onClick={handleCopyLink}>Copy link</Button>
                    <Button
                      onClick={handleExportCsv}
                    >Export link of all users as CSV</Button>
                  </div>
                </div>
                <div className="tableSearch">
                  <Form
                      form={form}
                      onFinish={onFinishSearch}
                      colon={false}
                      layout="vertical"
                      style={{
                        width: '100%',
                      }}
                      initialValues={{
                        date_for_search: [
                          dayjs(moment().format(dateFormat).valueOf(),
                              dateFormat),
                          dayjs(moment().format(dateFormat).valueOf(),
                              dateFormat),
                        ],
                        user: null,
                      }}
                      className={'twoColumns'}
                  >
                    <Form.Item
                        name={'user'}
                        label={'Staff'}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          options={[
                            {
                              value: null,
                              label: 'All'
                            },
                            ...(userData?.list?.map(item => {
                              return {
                                value: item.id,
                                label: `${item.email} - ${item.fullName || 'Not defined yet'}`
                              }
                            }) || [])
                          ]}
                      >
                      </Select>
                    </Form.Item>

                    <Form.Item rules={[{required: true}]} name="date_for_search" className={'formFieldDate'}
                               label={(
                                   <Fragment>
                                     <div className={'labelDate'}>
                                       <span>Date filter</span>
                                       <div className="quickFilter">
                                         <div onClick={() => changeFilterDate('yesterday')}>Yesterday</div>
                                         <div onClick={() => changeFilterDate('day')}>Today</div>
                                         <div onClick={() => changeFilterDate('week')}>This week</div>
                                         <div onClick={() => changeFilterDate('month')}>This month</div>
                                       </div>
                                     </div>
                                   </Fragment>
                               )}>
                      <DatePicker.RangePicker format={CONFIG.localDateFormat}
                                              style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item className={'submitRow'}>
                      <Button type="primary" block htmlType="submit">Search
                        now</Button>
                    </Form.Item>
                  </Form>
                </div>

                <TableRecords isShowAbsent={!!isShowAbsent} data={data} dateFrom={dateFrom} dateTo={dateTo}
                  reloadData={() => {
                    form.submit();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
