import {Fragment, useRef, useState} from 'react';
import {Button} from 'antd';

export const CameraPreview = ({handleApproveImage}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [snapshot, setSnapshot] = useState(null);
  const [error, setError] = useState('');

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        setStream(mediaStream);
        setIsStreaming(true);
        setError('');
      }
    } catch (err) {
      setError('Error accessing camera. Please make sure you have granted camera permissions.');
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setStream(null);
      setIsStreaming(false);
    }
  };

  const takeSnapshot = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const base64 = canvas.toDataURL('image/jpeg');
      setSnapshot(base64);
    }
  };

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        <center>Hãy chụp ảnh selfie tại bàn làm việc của bạn để được phê duyệt checkin thủ công.</center>
        <div
          style={{
            width: '100%',
            display: snapshot ? 'none' : 'block',
          }}
        >
          <div style={{
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: '#f0f0f0'
          }}>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: '100%', display: 'block' }}
            />
          </div>

          <canvas ref={canvasRef} style={{ display: 'none' }} />

          {error && (
              <div style={{ color: '#ff0000', marginBottom: '1rem' }}>
                {error}
              </div>
          )}

          <div style={{ display: 'flex', gap: '1rem' }}>
            {!isStreaming ? (
                <button
                    onClick={startCamera}
                    style={{
                      marginTop: '1rem',
                      padding: '0.5rem 1rem',
                      backgroundColor: '#4CAF50',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                >
                  Bật camera để chụp ảnh check-in
                </button>
            ) : (
                <>
                  <button
                      onClick={takeSnapshot}
                      style={{
                        marginTop: '1rem',
                        padding: '0.5rem 1rem',
                        backgroundColor: '#2196F3',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                  >
                    Chụp ảnh
                  </button>
                  {/*<button*/}
                  {/*    onClick={stopCamera}*/}
                  {/*    style={{*/}
                  {/*      padding: '0.5rem 1rem',*/}
                  {/*      backgroundColor: '#f44336',*/}
                  {/*      color: 'white',*/}
                  {/*      border: 'none',*/}
                  {/*      borderRadius: '4px',*/}
                  {/*      cursor: 'pointer'*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*  Dừng camera*/}
                  {/*</button>*/}
                </>
            )}
          </div>

        </div>

        {snapshot && (
            <div style={{ marginTop: '1rem', width: '100%' }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                justifyItems: 'center',
                alignItems: 'center',
                marginBottom: '0.5rem'
              }}>
                <div>Ảnh đã chụp</div>

                <Button
                    onClick={() => setSnapshot(null)}
                >Chụp lại ảnh khác</Button>
              </div>
              <img
                  src={snapshot}
                  alt="Snapshot"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #ddd'
                  }}
              />

              <Button
                  type={'primary'}
                  block
                onClick={() => {
                  // Stop camera
                  stopCamera();

                  handleApproveImage(snapshot);
                }}
              >Gửi ảnh này để check-in</Button>

              {/*<div style={{*/}
              {/*  marginTop: '0.5rem',*/}
              {/*  backgroundColor: '#f5f5f5',*/}
              {/*  padding: '0.5rem',*/}
              {/*  borderRadius: '4px'*/}
              {/*}}>*/}
              {/*  <p style={{ margin: '0 0 0.5rem 0' }}>Base64 string (truncated):</p>*/}
              {/*  <code style={{*/}
              {/*    display: 'block',*/}
              {/*    wordBreak: 'break-all',*/}
              {/*    fontSize: '12px'*/}
              {/*  }}>*/}
              {/*    {snapshot.substring(0, 64)}...*/}
              {/*  </code>*/}
              {/*</div>*/}
            </div>
        )}
      </div>
  );
};
